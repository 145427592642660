import { Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: ':id',
    // id-provider
    loadComponent: () => import('./pages/id-provider/id-provider.component'),
    children: [
      {
        path: '',
        redirectTo: 'reserve/top',
        pathMatch: 'full',
      },
      {
        path: 'reserve/login',
        loadComponent: () => import('./pages/login/login.component'),
      },
      {
        path: 'reserve',
        loadComponent: () => import('./pages/reserve-form/reserve-form.component'),
        loadChildren: () => import('./pages/reserve-form/reserve-form.routes'),
        canActivate: [ authGuard ],
      },
    ],
  },
];
