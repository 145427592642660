import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { catchError, of, tap } from 'rxjs';

interface LoginReserveReq {
  user_name: string;
  password: string;
}

interface UserRes {
  id: number;
  user_name: string;
  name: string;
  use: boolean;
  created: string;
  modified: string;
  fixed: boolean;
  failed_count: number;
  locked_time: any;
  notifications_count: any;
  read_notifications_count: any;
  roles_count: any;
  unread_notifications_count: any;
  medigleFacility: {
    id: number
    epark_id: number
    plan: number
    charge_name: string
    charge_department: string
    tel: string
    fax: string
    mail_address: string
    site_name: string
    site_logo_url: string
    site_logo_url_name: any
    site_img_url: string
    site_img_url_name: string
    site_base_url: string
    created: string
    start_date: string
    end_date: string
    site_color: string
    site_text_is_visible: number
    site_text: string
    renkei_mark_name: string
    renkei_mark_text: string
    status: number
    memo: string
    renkei_mark_use: number
    site_default: number
    site_is_index_off: number
    patient_use: number
    patient_markup: number
    recaptcha_use: number
    ip_access: string
    patients_one_word_message: string
    medical_professionals_one_word_message: string
    patients_message: string
    medical_professionals_message: string
    doctor_img_url: string
    doctor_img_url_name: string
    notifications_count: any
    read_notifications_count: any
    unread_notifications_count: any
    users_count: any
    facility_name: string
  };
  roles: Array<{
    id: number
    name: string
    role_name: string
    medigle_func_id_exists?: string
    description: string
    sort: number
    users_count: any
  }>;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  #httpClient = inject(HttpClient);

  myData = signal<UserRes | null>(null);

  login(req: LoginReserveReq) {
    return this.#httpClient.patch('institution/user/login-reserve', req);
  }

  logout() {
    return this.#httpClient.patch('institution/user/logout', {});
  }

  me() {
    return this.#httpClient.get<UserRes>('institution/user/me-reserve')
      .pipe(
        catchError(() => of(null)),
        tap((res) => {
          this.myData.set(res);
        }),
      );
  }
}
