import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from '../services/api/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.me().pipe(
    map((user) => {
      if (user !== null) {
        return true;
      }

      return router.createUrlTree(route.paramMap.has('id') ? [ `/${ route.paramMap.get('id') }/reserve/login` ] : [ '/' ], {
        queryParams: {
          redirect: route.queryParamMap.get('redirect') ?? state.url,
        },
      });
    }),
  );
};
export const authGuestGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.me().pipe(
    map((user) => {
      if (user === null) {
        return true;
      }

      if (route.queryParamMap.has('redirect')) {
        return router.parseUrl(route.queryParamMap.get('redirect') ?? '');
      }

      return router.createUrlTree(route.paramMap.has('id') ? [ `/${ route.paramMap.get('id') }/reserve/form0101` ] : [ '/' ]);
    }),
  );
};
