import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IGNORE_IF_DUPLICATED, Message } from '../models/message-model';

@Injectable({ providedIn: 'root' })
export class MessageStoreService {
  dialogMessage: Subject<Message> = new Subject<Message>();
  #queue: Message[] = [];
  #currentMessage: Message | null = null;

  push(value: Message) {
    if (IGNORE_IF_DUPLICATED.indexOf(value.type) >= 0) {
      if (this.#currentMessage?.type === value.type || this.#queue.some((m) => m.type === value.type)) {
        return;
      }
    }
    this.#queue.push(value);
    this.publishNext();
  }

  publishNext() {
    if (!!this.#currentMessage) {
      return;
    }
    const nextMessage = this.#queue.shift();
    if (nextMessage) {
      this.#currentMessage = nextMessage;
      switch (nextMessage.viewType) {
        case 'dialog':
          this.dialogMessage.next(nextMessage);
          break;
      }
    }
  }

  closed(value: Message) {
    this.#currentMessage = null;
    this.publishNext();
  }
}
