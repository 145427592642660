import {
  HttpContextToken,
  HttpErrorResponse,
  HttpFeature,
  HttpFeatureKind,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  withInterceptors,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { apiError } from 'projects/medigle-reserve-lib/src/lib/models/message-model';
import { LoadingSpinnerService } from 'projects/medigle-reserve-lib/src/lib/services/loading-spinner.service';
import { MessageStoreService } from 'projects/medigle-reserve-lib/src/lib/services/message-store.service';
import { catchError, finalize, throwError } from 'rxjs';


export const HIDE_MESSAGE_HTTP_CONTEXT_TOKEN = new HttpContextToken(() => false);
export const withReservationAPIInterceptor: (baseEndpoint: string) => HttpFeature<HttpFeatureKind.Interceptors> = (baseEndpoint: string) => withInterceptors([ getReserveAPIInterceptor(baseEndpoint) ]);

const getReserveAPIInterceptor: (baseEndpoint: string) => HttpInterceptorFn = (baseEndpoint) =>
  (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const spinner = inject(LoadingSpinnerService);
    const message = inject(MessageStoreService);
    spinner.show();
    if (!request.url.match(/^(https?:)?\/\//)) {
      const convertedBody = isInFileReq(request.body) ? toFormData(request.body) : request.body;
      const isUndeceiveFormDataMethod = convertedBody instanceof FormData && [ 'PATCH', 'PUT', 'DELETE' ].includes(request.method.toUpperCase());

      if (isUndeceiveFormDataMethod) {
        if (convertedBody instanceof FormData) {
          convertedBody.append('_method', request.method.toUpperCase());
        }
      }

      request = request.clone({
        url: `${ baseEndpoint }/${ request.url }`,
        withCredentials: true,
        setHeaders: {
          'Accept': 'application/json',
        },
        method: isUndeceiveFormDataMethod ? 'POST' : request.method,
        body: convertedBody,
      });
    }
    return next(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!request.context.get(HIDE_MESSAGE_HTTP_CONTEXT_TOKEN)) {
          if (error.status === 422 && error.error?.errors) {
            // バリデーション
            message.push(apiError(Object.values(error.error.errors).join('\n')));
          } else if (error.status === 401) {
            message.push(apiError('認証に失敗しました、再度ログインしてください'));
          } else {
            message.push(apiError('エラーが発生しました')); // TODO: 暫定
          }
        }

        return throwError(() => error);
      }),
      finalize(() => spinner.hide()),
    );
  };


const isInFileReq = (req: any): boolean => {
  if (!(req instanceof Object)) {
    return false;
  }

  const body = req as Record<string, unknown>;
  return Object.keys(body).some(key => {
    const value = body[key];

    if (value && !(value instanceof File) && value instanceof Object) {
      return isInFileReq(value);
    }
    return value instanceof File;
  });
};

const toFormData = (req: any, targetForm?: FormData, parentKey?: string): FormData => {
  // 再帰的に使用する関数
  // Objectを再帰的に探索し、FormDataに変換する

  console.log(targetForm);
  const form = targetForm ?? new FormData();
  const baseKey = parentKey ?? '';
  const getKey = (key: string) => baseKey ? `${ baseKey }[${ key }]` : key;

  if (!(req instanceof Object)) {
    return form;
  }

  const body = req as Record<string, unknown>;
  console.log(body);
  Object.keys(body).forEach(key => {
    const value = body[key];
    const combinedKey = getKey(key);

    console.log(key);
    switch (typeof value) {
      case 'string':
      case 'number':
        form.append(combinedKey, value.toString());
        break;
      case 'boolean':
        form.append(combinedKey, value ? '1' : '0');
        break;
      case 'object':
        if (value instanceof File) {
          form.append(combinedKey, value);
        } else if (value instanceof Date) {
          form.append(combinedKey, value.toISOString());
        } else if (value === null) {
          form.append(combinedKey, '');
        } else {
          toFormData(value, form, combinedKey);
        }
        break;
      default:
        break;
    }
  });

  return form;
};
