export type MessageType = 'apiError' | 'frontError';
export type MessageViewType = 'dialog';

export const IGNORE_IF_DUPLICATED = ['apiError'];

export interface Message {
  type: MessageType;
  viewType: MessageViewType;
  text: string;
}

export const apiError = (text: string): Message => ({ type: 'apiError', viewType: 'dialog', text });
