import { Injectable, inject } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoadingSpinnerComponent } from '../components/loading-spinner/loading-spinner.component';

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  #overlay = inject(Overlay);
  #overlayRef: OverlayRef | null = null;
  #showCount: number = 0;

  public show() {
    if (!this.#overlayRef) {
      this.#overlayRef = this.#overlay.create();
    }
    const spinnerOverlayPortal = new ComponentPortal(LoadingSpinnerComponent);
    this.#showCount++;
    if (this.#showCount === 1) {
      setTimeout(() => {
        if (this.#overlayRef) {
          this.#overlayRef.attach(spinnerOverlayPortal);
        }
      });
    }
  }

  public hide() {
    if (this.#showCount > 0) {
      this.#showCount--;
    }
    if (this.#showCount === 0 && !!this.#overlayRef) {
      this.#overlayRef.detach();
    }
  }
}
